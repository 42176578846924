import Amplify, { Auth } from 'aws-amplify';
import config from "./config"

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
      endpoints: [
        {
          name: "associates-surveys",
          endpoint: config.apiGateway.API_BASE_URL + "/surveys/associates",
          custom_header: async () => {
           return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
          },
          region: config.apiGateway.REGION
        },
        {
          name: "associates-surveys-email-options",
          endpoint: config.apiGateway.API_BASE_URL + "/surveys/associates/email-options",
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
          },
          region: config.apiGateway.REGION
        },
        {
          name: "associates-salesforce-create",
          endpoint: config.apiGateway.API_BASE_URL + "/salesforce/create",
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
          },
          region: config.apiGateway.REGION
        },
        {
          name: "associates-salesforce-update",
          endpoint: config.apiGateway.API_BASE_URL + "/salesforce/update",
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
          },
          region: config.apiGateway.REGION
        },
        {
          name: "circulytics-surveys",
          endpoint: config.apiGateway.API_BASE_URL + "/surveys/circulytics",
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
           },
          region: config.apiGateway.REGION
        },
        {
          name: "circulytics-surveys-benchmark-pending",
          endpoint: config.apiGateway.API_BASE_URL + '/surveys/circulytics/benchmark-pending',
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
           },
          region: config.apiGateway.REGION
        },
        {
          name: "circulytics-benchmarks-run",
          endpoint: config.apiGateway.API_BASE_URL + '/benchmarks/circulytics/run',
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
           },
          region: config.apiGateway.REGION
        },
        {
          name: "circulytics-scorecards-generate",
          endpoint: config.apiGateway.API_BASE_URL + '/scorecards/circulytics/generate',
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
           },
          region: config.apiGateway.REGION
        },
        {
          name: "pre-signed-url",
          endpoint: config.apiGateway.API_BASE_URL + '/pre-signed-url',
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
           },
          region: config.apiGateway.REGION
        },
        {
          name: "salesforce-query",
          endpoint: config.apiGateway.API_BASE_URL + '/salesforce/query',
          custom_header: async () => {
            return { Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken() }
           },
          region: config.apiGateway.REGION
        },
      ]
    },
    Storage: {
      AWSS3: {
          bucket: config.storage.BUCKET_NAME,
          region: config.storage.REGION
      }
    }
  });
