const envSettings = {
  apiGateway: {
    REGION: "eu-west-2",
    API_BASE_URL: process.env.GATSBY_BASE_API_URL
  },
  cognito: {
    REGION: "eu-west-2",
    APP_CLIENT_ID: process.env.GATSBY_COGNITO_APP_CLIENT_ID,
    USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID,
    IDENTITY_POOL_ID: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID
  },
  storage: {
    REGION: "eu-west-2",
    BUCKET_NAME: process.env.GATSBY_CIRCULYTICS_SCORECARDS_BUCKET
  }
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...envSettings,
};

export default config;
